// Override styles for table structure not in other applications
.Form4014a, .Form4027i, .Form4015, .Form4015a, .Form4017, .Form4018, .Form4022, .Form4023, .Form4024, .Form4046 {
  th {
    background: #fafafa !important; // .bg-light
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background: #fafafa !important;
}

.bg-light-teal {
  background: $light-teal !important;

  input {
    background: #FFF;
  }
}

.SortableTable thead th,
.table thead th {
  border-bottom-width: 1px;
}

.MasterSales .table-fixed .table-responsive {
  border-bottom: 1px solid #dee2e6;
  max-height: 60vh !important;
}

.table-responsive {
  border: 1px solid #DDD;
  border-radius: 0.25rem;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
}

.table-striped tbody tr.row-highlight {
  background: #31a09d !important;
}

.responsive-table-eq-height .table-responsive {
  height: 100%;
  overflow-y: hidden;
}

.custom-checkbox input[type="checkbox"] + label:after {
  z-index: 0 !important;
}

/**
* Fixed table headers/footers functionality+positioning
**/
.position-top-50 th {
  top: 50px !important;
}

.position-top-75 th {
  top: 75px !important;
}

.position-top-100 th {
  top: 100px !important;
}

.fixed-height-75 {
  max-height: 75px !important;
  min-height: 75px !important;

  th {
    max-height: 73px !important;
    min-height: 73px !important;
  }
}

.table-fixed {
  thead tr th {
    background: #fafafa !important;
  }

  .table-responsive {
    overflow-y: auto;
    max-height: 90vh !important;
  }

  th, tfoot tr td {
    position: sticky !important;
    z-index: 200 !important;
    background-clip: padding-box;
  }

  th, td {
    &.top-left-fixed-header {
      z-index: 250 !important;
    }

    &.left-fixed {
      background-clip: padding-box;
      position: sticky !important;
      left: 0;
    }

    &.body-col-striped-fixed {
      background-color: #fafafa !important; // .bg-light
      filter: none !important;
      z-index: 200 !important;
    }
  }

  tr:first-child th {
    top: 0 !important;
  }

  // add striped rows
  tbody tr:nth-child(even) td {
    filter: contrast(0.95);
  }

  table {
    border-collapse: separate; // Don't collapse
    border-spacing: 0;
  }

  table th {
    // Apply both top and bottom borders to the <th>
    border-top: 1px solid #dee2e6 !important;
    border-bottom: 2px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
  }

  table td {
    // For cells, apply the border to one of each side only (right but not left, bottom but not top)
    border-bottom: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
  }

  table th:first-child,
  table td:first-child {
    // Apply a left border on the first <td> or <th> in a row
    border-left: 1px solid #dee2e6 !important;
  }

  td.bg-light-teal.border-left-3,
  th.bg-light-teal.border-left-3 {
    border-left-color: rgba(0, 0, 0, 0.14) !important;
  }

  tfoot td.bg-light-teal {
    border-top-color: rgba(0, 0, 0, 0.14) !important;
    border-bottom-color: rgba(0, 0, 0, 0.14) !important;
  }

  tbody tr:last-child td {
    border-bottom: 0;
  }

  tfoot tr td {
    border-top-width: 2px;
    bottom: 0 !important;
    box-shadow: 1px 2px 0 0 #dee2e6;
  }

  tfoot tr td.bg-light-teal {
    box-shadow: 1px 2px 0 0 #9ba0a5;
  }
}

.Report4030Table {
  thead tr th, tbody tr td {
    border-right: none !important;
  }
}

.Statistics, .PsdSalesSampleReport, .MonitorStudy {
  .table-responsive {
    box-shadow: none !important;
    border: none;
    border-radius: 0 !important;
  }
}

.SplitTable {
  thead th.bg-light-teal {
    border-bottom: 2px solid #d4d5d5 !important;
    border-right: 1px solid #d4d5d5 !important;
  }

  tbody td.bg-light-teal {
    border-bottom: 1px solid #d4d5d5 !important;
    border-right: 1px solid #d4d5d5 !important;
  }

  tbody tr:hover td {
    background: $light-teal !important;
  }

  tbody tr:hover td.bg-light-teal {
    background: #d9e3e3 !important;
  }
}

.SplitTable.table-fixed tbody tr:nth-child(even) td {
  filter: none !important;
}

// Split tables
.table-bordered th.border-left-3,
.table-bordered td.border-left-3 {
  border-left: 3px solid rgba(0, 0, 0, 0.14) !important;
}

.table-bordered th.border-left-3.bg-light-teal,
.table-bordered td.border-left-3.bg-light-teal {
  border-left: 3px solid rgba(0, 0, 0, 0.14) !important;
}

.empty-cell {
  background: #fafafa !important;
}

// Accessibility overrides
.RecapComparisonReport, .PsdMasterSales, .MonitorStudy, .CedRecap, .Modifiers,
.CountyReport4017, .LocalUnitReport4017, .Statistics, .Report4030,
.Report4030Admin, .Report4030AdminDashboard, .MasterSales, .Form4027i,
.Form4017, .Form4018V1, .Form4018V2, .Form4022, .Form4024 {
  thead.bg-light, td.bg-light, tr.bg-light, th.bg-light, tfoot.bg-light {
    background: #fafafa !important;
  }
}
