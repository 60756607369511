@import '~@reasoncorp/kyber-js/dist/scss/themes/misuite-v2/theme-misuite-v2';

$light-teal: #edf2f2;

@import "tables";

.max-w-125 {
  max-width: 125px;
}

.nav-tabs-header {
  font-weight: 600;
  background: #fafafa !important;

  .nav-link {
    color: map-get($theme-colors, secondary) !important;
  }

  .nav-link.active {
    background: none !important;
  }
}

.StudyCertification .custom-control-label {
  color: #000 !important;
}

.no-properties-row {
  position: relative;

  .custom-switch {
    margin-top: 0.5rem;
  }
}

.PersonalPropertyReportQuestion {
  .col:first-child {
    background: #fafafa;
    padding: 1rem;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  &:first-child .col:first-child {
    border-top: none !important;
  }
}

// Form4022 Modals
.Form4022ModalHeader {
  background: #fafafa;
  font-weight: 500;
  border-bottom: 1px solid #DDD;
}

.Form4022ModalSection {
  border-bottom: 1px solid #DDD;
}
